import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./Contact.css"

const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize options as needed
  }, []);

  return (
    <section className="contact" id="contact">
      <div className="container">
        <h2 className="title" data-aos="fade-up" data-aos-duration="800">Contact Info</h2>
        <p
          className="subtitle"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          Have any questions? We'd love to hear from you.
        </p>

        <div className="contact-content">
          <div className="contact-form" data-aos="fade-right" data-aos-duration="1000">
            <form>
              <div className="form-group">
                <input type="text" placeholder="Full Name" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <textarea placeholder="Your Message" required></textarea>
              </div>
              <button type="submit">Contact Us</button>
            </form>
          </div>

          <div className="contact-info" data-aos="fade-left" data-aos-duration="1000">
            <div className="info-item">
              <h3>Email</h3>
              <p>himanshu.sharma@upkarak.com</p>
              <p>shabnam.thakur@upkarak.com</p>
              <p>info@upkarak.com</p>
            </div>
            <div className="info-item">
              <h3>Call</h3>
              <p>+91 99151 76805</p>
              <p>+91 96464 73802</p>
            </div>
          </div>

          <div className="contact-info" data-aos="fade-left" data-aos-duration="1000">
            <div className="info-item">
              <h3>Address</h3>
              <p>
                F 195, Phase 8B, Industrial Area
                <br />
                Sector 74, Mohali, Punjab
                <br />
                160055, India
              </p>
              <br />
              <h3>Registered Address</h3>
              <p>
                32 Kilan Area Nangal
                <br />
                Punjab, India
              </p>

            </div>
          </div>


          <footer className="social-icons">
          <a href="https://www.facebook.com/UpkarakTechSolutions" target="_blank" rel="noreferrer">
            <img src={require('../../assets/Icons/facebook.png')} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/upkarak_tech_solutions" target="_blank" rel="noreferrer">
            <img src={require('../../assets/Icons/instagram.png')} alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/@upkaraktechsolutions" target="_blank" rel="noreferrer">
            <img src={require('../../assets/Icons/youtube.png')} alt="YouTube" />
          </a>
          <a href="https://www.linkedin.com/company/upkarak-tech-solutions-pvt-ltd" target="_blank" rel="noreferrer">
            <img src={require('../../assets/Icons/linkedin.png')} alt="LinkedIn" />
          </a>
        </footer>
        </div>
        
      </div>
    </section>
  );
};

export default Contact;


