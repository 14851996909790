import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Services.css";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="services" id="services">
      <div className="container">
        <h2 data-aos="fade-up" data-aos-duration="800">About The Services</h2>
        <p
          className="subtitle"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          We produce meaningful experiences that captivate customers
        </p>

        <div className="services-grid">
          <div
            className="service-card"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <h4>Website and Mobile Design</h4>
            <p>
              We create visually appealing and user-friendly designs for websites and mobile
              applications. Our designs are responsive, intuitive, and tailored to enhance user
              engagement and brand identity.
            </p>
          </div>

          <div
            className="service-card"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            <h4>Web Development</h4>
            <p>
              Our web development services include building dynamic, scalable, and high-performance
              websites using modern frameworks like React, Angular, and Vue.js. We ensure seamless
              functionality and optimal user experience.
            </p>
          </div>

          <div
            className="service-card"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <h4>Software Development</h4>
            <p>
              We develop robust software solutions tailored to meet your business needs. From enterprise
              applications to cloud-based solutions, we use cutting-edge technologies to deliver secure
              and scalable software.
            </p>
          </div>

          <div
            className="service-card"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="600"
          >
            <h4>Mobile App Development</h4>
            <p>
              We specialize in developing high-performance mobile applications for iOS and Android platforms.
              Our apps are feature-rich, secure, and optimized for superior user experience and engagement.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
