import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './About.css';
import groupImg from '../../assets/images/Who we are image.png';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // You can adjust the duration or other settings here
  }, []);

  return (
    <section className="about" id="about">
      <div className="container">
        <h2 data-aos="fade-up" data-aos-duration="800">Who We Are?</h2>
        <p className="subtitle" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
          A Leading Software Company
        </p>

        <div className="about-content">
          <div className="about-image" data-aos="fade-right" data-aos-duration="1000">
            <img src={groupImg} alt="Team at Upkarak Tech Solutions" />
          </div>

          <div className="about-text" data-aos="fade-left" data-aos-duration="1000">
            <h3>Know About Us</h3>
            <p>
              Upkarak Tech Solutions is a Mohali-based leading software company specializing in website development/design, mobile app development/design, and product engineering. Incepted in 2016, we grow every year by staying ahead of emerging technologies and building a dynamic team.
            </p>
            <h3>Professional Software Solutions</h3>
            <ul>
              <li>Best-in-class solutions for your business.</li>
              <li>Innovative ideas, concepts, and approaches for client success.</li>
              <li>Dedicated post-implementation support.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
