import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/images/Logo.svg';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <Link to="/">
            <img src={logo} alt="Company Logo" className="logo" />
          </Link>
        </div>
        <div className="header-right">
          <Link to="/contact" className="contact-us">CONTACT US</Link>
          <div className="hamburger" onClick={toggleMenu}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        {menuOpen && (
          <nav className="menu">
            <ul>
              <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
              <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
              <li><Link to="/vision" onClick={toggleMenu}>Vision</Link></li>
              <li><Link to="/reviews" onClick={toggleMenu}>Reviews</Link></li>
              <li><Link to="/contact" className='contact-mobile' onClick={toggleMenu}>Contact Us</Link></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
