import React, { useState, useEffect } from 'react';
import './Hero.css';
import Header from '../Header/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import one from '../../assets/Icons/01.svg';
import two from '../../assets/Icons/02.svg';
import three from '../../assets/Icons/03.svg';

const quotes = [
  [
    [
      { text: "We ", className: "" },
      { text: "Shape up", className: "highlight" },
      { text: " your Innovative", className: "" }
    ],
    [
      { text: "Ideas into", className: "" }
    ],
    [
      { text: "Successful", className: "highlight" },
      { text: " ", className: "" },
      { text: "Products.", className: "outline-text" }
    ]
  ],
  [
    [
      { text: "Empowering your ", className: "" },
      { text: "Vision", className: "highlight" },
      { text: " into", className: "" }
    ],
    [
      { text: "Cutting-edge", className: "" }
    ],
    [
      { text: "Solutions.", className: "outline-text" }
    ]
  ],
  [
    [
      { text: "Transforming ", className: "" },
      { text: "Innovative Ideas", className: "highlight" }
    ],
    [
      { text: "into", className: "" }
    ],
    [
      { text: "Digital Realities.", className: "outline-text" }
    ]
  ]
];

const typingDelay = 60;      // Delay between each letter
const linePauseDelay = 200;  // Delay between lines
const pauseDelay = 1000;     // Pause after the full quote is typed
const fadeDuration = 500;    // Fade-out duration before switching quote

const Hero = () => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const currentQuote = quotes[currentQuoteIndex];

  const getLineTotalLength = (line) =>
    line.reduce((acc, seg) => acc + seg.text.length, 0);

  useEffect(() => {
    if (!isFading) {
      if (currentLineIndex < currentQuote.length) {
        const totalLength = getLineTotalLength(currentQuote[currentLineIndex]);
        if (currentCharIndex < totalLength) {
          const timeout = setTimeout(() => {
            setCurrentCharIndex((prev) => prev + 1);
          }, typingDelay);
          return () => clearTimeout(timeout);
        } else {
          if (currentLineIndex < currentQuote.length - 1) {
            const timeout = setTimeout(() => {
              setCurrentLineIndex((prev) => prev + 1);
              setCurrentCharIndex(0);
            }, linePauseDelay);
            return () => clearTimeout(timeout);
          } else {
            const timeout = setTimeout(() => {
              setIsFading(true);
            }, pauseDelay);
            return () => clearTimeout(timeout);
          }
        }
      }
    } else {
      const timeout = setTimeout(() => {
        setIsFading(false);
        setCurrentQuoteIndex((prev) => (prev + 1) % quotes.length);
        setCurrentLineIndex(0);
        setCurrentCharIndex(0);
      }, fadeDuration);
      return () => clearTimeout(timeout);
    }
  }, [currentQuoteIndex, currentLineIndex, currentCharIndex, isFading, currentQuote]);

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const renderLine = (segments, charCount) => {
    let remaining = charCount;
    const rendered = [];
    segments.forEach((seg, idx) => {
      if (remaining > seg.text.length) {
        rendered.push(
          <span key={idx} className={seg.className}>
            {seg.text}
          </span>
        );
        remaining -= seg.text.length;
      } else if (remaining > 0) {
        rendered.push(
          <span key={idx} className={seg.className}>
            {seg.text.substring(0, remaining)}
          </span>
        );
        remaining = 0;
      }
    });
    return rendered;
  };

  return (
    <section className="hero" id="home">
      <Header />
      <div className="hero-content" data-aos="fade-up" data-aos-duration="800">
        <h1 className={isFading ? "fade-out" : "fade-in"}>
          {currentQuote.map((line, index) => (
            <div key={index}>
              {index < currentLineIndex ? (
                line.map((seg, i) => (
                  <span key={i} className={seg.className}>
                    {seg.text}
                  </span>
                ))
              ) : index === currentLineIndex ? (
                renderLine(line, currentCharIndex)
              ) : (
                ""
              )}
            </div>
          ))}
        </h1>
      </div>
      <div className="services-preview">
        <div className="service-item" data-aos="fade-right" data-aos-duration="800">
          <img src={one} alt="Website Design Icon" className="service-number" />
          <span className="service-text">Website Design</span>
        </div>
        <div className="service-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
          <img src={two} alt="Website Development Icon" className="service-number" />
          <span className="service-text">Website Development</span>
        </div>
        <div className="service-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="400">
          <img src={three} alt="Mobile App Development Icon" className="service-number" />
          <span className="service-text">Mobile App Development</span>
        </div>
      </div>
    </section>
  );
};

export default Hero;
