import React, { useEffect } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Services from './components/Services/Services';
import Vision from './components/Vision/Vision';
import Reviews from './components/Reviews/Reviews';
import Contact from './components/Contact/Contact';
import AutoScrollWrapper from './components/AutoScroll/AuroScrollWrapper';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease', once: true, mirror: false, });
  }, []);

  return (
    <Router>
      <div className="App">
        <AutoScrollWrapper>
          <ScrollToTopButton />
          <Hero id="hero" />
          <About id="about" />
          <Services id="services" />
          <Vision id="vision" />
          <Reviews id="reviews" />
          <Contact id="contact" />
        </AutoScrollWrapper>
      </div>
    </Router>
  );
}

export default App;
