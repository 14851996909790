import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AutoScrollWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const targetId = location.pathname === '/' ? 'hero' : location.pathname.substring(1);
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, [location]);

  useEffect(() => {
    let isAutoScrolling = false;
    let scrollTimeout;

    const handleWheel = (event) => {
      if (isAutoScrolling) return;
      
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        const sections = document.querySelectorAll('section');
        const scrollY = window.scrollY;
        const viewportHeight = window.innerHeight;
        let currentSectionIndex = 0;

        // Find current section
        sections.forEach((section, index) => {
          const sectionTop = section.offsetTop;
          if (scrollY >= sectionTop - viewportHeight / 10) {
            currentSectionIndex = index;
          }
        });

        // Scrolling down
        if (event.deltaY > 0 && currentSectionIndex < sections.length - 1) {
          isAutoScrolling = true;
          const nextSection = sections[currentSectionIndex + 1];
          window.scrollTo({
            top: nextSection.offsetTop,
            behavior: 'smooth'
          });
          setTimeout(() => {
            isAutoScrolling = false;
          }, 1500);
        }
        
        // Scrolling up
        // if (event.deltaY < 0 && currentSectionIndex > 0) {
        //   isAutoScrolling = true;
        //   const prevSection = sections[currentSectionIndex - 1];
        //   window.scrollTo({
        //     top: prevSection.offsetTop,
        //     behavior: 'smooth'
        //   });
        //   setTimeout(() => {
        //     isAutoScrolling = false;
        //   }, 1000);
        // }
      }, 60);
    };

    window.addEventListener('wheel', handleWheel, { passive: true });
    
    // Clean up
    return () => {
      window.removeEventListener('wheel', handleWheel);
      clearTimeout(scrollTimeout);
    };
  }, []);

  return <>{children}</>;
};

export default AutoScrollWrapper;
