import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Reviews.css";
import image from "../../assets/Icons/Inverted Commas.svg";
// import imange1 from "../../assets/images/Customer Image.png";

const reviewsData = [
  {
    text: "We have been working with Upkarak team for over 5 years now. I must say we have come a long way together and Upkarak team has done a wonderful job.",
    name: "Ajay Patel",
    role: "CEO. SMA, Inc.",
  },
  {
    text: "Upkarak team is very professional and supportive. Their quality of work is outstanding. I recommend them for your App development.",
    name: "Aaron Meier",
    role: "CO-Founder, Chill",
  },
  {
    text: "Can't say enough things about Upkarak team. Incredible work, fast, effective and responsive communicator.",
    name: "DialSight",
    role: "CO-Founder",
  },
  {
    text:
      "Upkarak team met all of our requiremants with their wide breadth of experties in multiple technologies. We completed multiple projects over a couple of years, and they were always very open, responsive, and reasonable - a great partner for a startup like us!",
    name: "Vacit Arat",
    role: "CEO. HIA, Inc.",
  },
  {
    text:
      "I believe, any engineering team should be able to understand the requirements, deliver on time, deliver according to the requirements. With Upkarak team we have have found the team that can provide all three.",
    name: "Jacque Keats",
    role: "COO. TODPro",
  },
  {
    text:
      "We thank Upkarak team for the wonderful job in helping us develop our program. Everyone was professional, excellent and hard working. Thanks to them, we were able to achieve our goal on time.",
    name: "Jaspreet Singh",
    role: "CO-Founder, Load Karma",
  },
  {
    text:
      "I found Upkarak on UpWork to create a new website for my buisnes as my old website was inadequate. My goals for this project were to create a better and user-friendly website to attract more customers. They designed, developed and configured my website. The project management was very impressive with good communication.",
    name: "KanexPro",
    role: "CO-Founder",
  },
  {
    text:
      "Upkarak team is very reliable and hardworking. You can count on them to get your work done.",
    name: "Pena Life",
    role: "CO-Founder",
  }
];

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewsData.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="reviews" id="reviews">
      <div className="container">
        <div className="top-title" data-aos="fade-up" data-aos-duration="800">
          <h2>Client's Reviews</h2>
          <p className="subtitle">
            We strive to provide best solutions that are well-researched and completely tested
          </p>
        </div>

        <div className="reviews-content">
          <div className="review-card" data-aos="fade-up" data-aos-duration="1000">
            <div className="review-text" data-aos="fade-right" data-aos-duration="800">
              <img src={image} alt="quote" />
            </div>
            <div className="review-text" data-aos="fade-left" data-aos-duration="800">
              <p>{reviewsData[currentIndex].text}</p>
            </div>
            <div className="reviewer" data-aos="fade-up" data-aos-duration="800">
              <h4>{reviewsData[currentIndex].name}</h4>
              <p>{reviewsData[currentIndex].role}</p>
            </div>
            {/* 3D Cube Progress Indicator */}
            <div className="cube-progress" data-aos="fade-up" data-aos-duration="800">
              {reviewsData.map((_, index) => (
                <div
                  key={index}
                  className={`cube ${index === currentIndex ? "active" : ""}`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
