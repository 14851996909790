import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Vision.css";

const Vision = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="vision" id="vision">
      <div className="container">
        <h2 data-aos="fade-up" data-aos-duration="800">Our Vision & Mission</h2>
        <p
          className="subtitle"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          Our mission is to constantly grow as knowledgeable IT company
        </p>

        <div className="vision-content">
          <div className="vision-items">
            <div className="vision-item" data-aos="fade-right" data-aos-duration="800">
              <h3>Honesty</h3>
              <p>
                We are strong believers that honesty is the only means for success and therefore, we keep open and
                honest communication with all our clients. As a client, you will be completely transparent to every step
                and every decision in every process.
              </p>
            </div>

            <div className="vision-item" data-aos="fade-up" data-aos-duration="800">
              <h3>Tailored Solutions</h3>
              <p>
                We believe and we know that every industry and every company is unique. We, therefore, do not retrofit
                any company into an existing format but provide insights that can help in improv- ing the company's
                performance on digital platforms.
              </p>
            </div>

            <div className="vision-item" data-aos="fade-left" data-aos-duration="800">
              <h3>Due Diligence</h3>
              <p>
                It is our commitment to our customers that we strive to provide them with solutions that are well
                researched and completely tested. The testing is carried out across projects irrespective of the size of
                the company or the project itself.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
